<script>
import { useMeta } from 'vue-meta'
import Menu from '@/components/Heineken-cvs-Q3/Menu'
import Alert from '@/components/Alert'

export default {
	name: "Heineken-cvs-Q3_home",
	data() {
		return {
			isActiveAlert:false
		};
	},
	setup () {
		useMeta(
		{ 
			title: '下班就是要海尼根！',
			description: '2024/5/1 - 5/28至指定通路，購買海尼根全系列任三罐啤酒，登錄發票就抽海尼根周邊商品!!!' 
		})		
	},
	components: {
		Menu,
		Alert

	},
	computed: {
	},
	watch: {
	},
	mounted() {
	},
	methods: {
		checkBrowser(){
			// console.log("checkBrowser")
			let vm=this
			var useragent = navigator.userAgent;
			var rules = ['WebView','(iPhone|iPod|iPad)(?!.*Safari\/)','Android.*(Instagram|Line|FBAN|FBAV)']
			var regex = new RegExp(`(${rules.join('|')})`, 'ig');
			if(Boolean(useragent.match(regex))){
				vm.isActiveAlert=true
			}else{
				location.href="https://heineken-event.webgene.com.tw/auth/redirect/heineken-cvs-q3"
			}
		},
	},
};
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
